<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper
            :expandable="true"
            v-model="customerHelper"
            header="Buscar Cliente"
            :style="{ width: '85%' }"
            :headers="customersHeaders"
            :headersExpandable="shippingsHeaders"
            :rows="customers"
            rowsExpandable="shippings"
            @selected="selectCustomer"
            @selected-detail="selectShipping"
        />
        <Toast />
        <div class="col-12">
            <div class="card">
                <Panel header="Terminal Virtual">
                    <BasicFormToolbar :actions="['new', 'refresh']" @new="newPayment" @save="save" @refresh="refresh">
                        <template #custom-left>
                            <Button @click="save" class="p-button-success mr-2">
                                <img alt="logo" src="/images/icons/button/pay.svg" style="width: 1.5rem" />
                                <span class="ml-2">Pagar</span>
                            </Button>
                        </template>
                    </BasicFormToolbar>
                    <Fieldset :toggleable="true" legend="Detalle de Orden">
                        <div class="formgrid grid p-fluid">
                            <FormInputText :rows="2"  :textArea="true" wrapperClass="field col-12 xl:col-12" label="Concepto" v-model="paymentParameters.concept"  />
                            <FormDropdown v-model="paymentParameters.type" label="Tipo" wrapperClass="field col-12 md:col-4 xl:col-4" :options="[ { id: 2, text: 'Venta' }, { id: 3, text: 'Venta MSI'}]" optionLabel="text" optionValue="id" />
                            <FormDropdownComplex :labelInOption="'c_Moneda - Descripcion'" :labelInValue="'c_Moneda'" :wrapperClass="'field col-12 md:col-4 xl:col-3'" label="Moneda" v-model="paymentParameters.orderInformationAmountDetails.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" />
                            <FormInputNumber label="Importe" wrapperClass="field col-12 md:col-4 xl:col-4" v-model="paymentParameters.orderInformationAmountDetails.totalAmount" mode="currency" />
                            <FormInputSwitch wrapperClass="field col-12 md:col-4 xl:col-1" label="Capturar?"  v-model="paymentParameters.capture" />
                            <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                            <FormDropdown v-if="paymentParameters.type == 3" wrapperClass="field col-12 md:col-4 xl:col-4" label="MSI" v-model="paymentParameters.installmentInformation.totalCount" :options="msi" optionValue="value" optionLabel="text" />
                            </FadeInOut>
                             <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                              <FormInputNumber :label="'Importe Parcial'" :disabled="true" mode="currency" v-if="paymentParameters.type == 3" wrapperClass="field col-12 md:col-4 xl:col-4"/>
                            </FadeInOut>
                        </div>
                    </Fieldset>
                    <br />
                    <Fieldset :toggleable="true" legend="Datos de Facturacion">
                        <BasicFormToolbar :actions="['new', 'save', 'search']" @new="newCustomer" @save="saveCustomer" @search="customerHelper.visible = true" >
                          <template #custom-left>
                            <Button @click="sendWhatsapp"  style="width: 7.2rem;font-size: 12px;" class="p-button-success " >
                                
                                <span class="ml-2"  > <i class="pi pi-whatsapp"></i> WhatsApp</span>
                            </Button>
                        </template>
                        </BasicFormToolbar>
                        <div class="p-fluid formgrid grid">
                            <FormInputText wrapperClass="field col-12 xl:col-4" label="Nombre" v-model="paymentParameters.orderInformationBillTo.name" :valid="customerValidate.validations.name" />
                            <FormInputText wrapperClass="field col-12 md:col-6 xl:col-4" label="E-mail" v-model="paymentParameters.orderInformationBillTo.email" :valid="customerValidate.validations.buyerInformation_email"
                                validation="Favor de llenar el campo con formato de correo '***@****.****'"/>
                            <FormInputText wrapperClass="field col-12 md:col-6 xl:col-4" label="Telefono" v-model="paymentParameters.orderInformationBillTo.phoneNumber" />
                            <Panel header="Direccion" class="hidden">
                                <BasicFormToolbar :actions="['new', 'delete']" @new="newShipping" @delete="deleteShipping" />
                                <div class="p-fluid formgrid grid">
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-5" label="Nombre" v-model="paymentParameters.orderInformationBillTo.firstName"  />
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-6" label="Apellido" v-model="paymentParameters.orderInformationBillTo.lastName"  />
                                    <FormInputSwitch :wrapperClass="'field col-1'" label="Default" v-model="paymentParameters.orderInformationBillTo.is_default" />
                                    <FormInputText wrapperClass="field col-12 xl:col-5" label="Direccion #1" v-model="paymentParameters.orderInformationBillTo.address1"  />
                                    <FormInputText wrapperClass="field col-12 xl:col-5" label="Direccion #2" v-model="paymentParameters.orderInformationBillTo.address2" />
                                    <FormInputText wrapperClass="field col-12 md:col-3 xl:col-2" label="Codigo Postal" v-model="paymentParameters.orderInformationBillTo.postalCode" />
                                    <Country wrapperClass="field col-12 md:col-9 xl:col-4" label="Pais" v-model="paymentParameters.orderInformationBillTo.country" />
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-4" label="Estado" v-model="paymentParameters.orderInformationBillTo.district" />
                                    <FormInputText wrapperClass="field col-12 md:col-6 xl:col-4" label="Ciudad" v-model="paymentParameters.orderInformationBillTo.locality" />
                                </div>
                            </Panel>
                        </div>
                    </Fieldset>
                    <br />
                    <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                        <CardTokenization @deleted-payment="deletedPaymentInstrument" 
                        @created="createdPaymentInstrument" 
                        @is-new-default-payment="isNewDefaultPaymentInstrument" 
                        ref="card" v-show="customer.id" 
                        :paymentInstruments="customer.paymentInstruments" 
                        :customer="customer" v-model="paymentParameters" 
                        :is_shipping_save="true"/>
                    </FadeInOut>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
import CardTokenization from "./Components/CardTokenization.vue";
import { PaymentParameters } from '../../../models/custom/business_wallet/PaymentParameters';
import { BWCustomer } from '../../../models/custom/business_wallet/BWCustomer';
import { BWShippingAddress } from '../../../models/custom/business_wallet/BWShippingAddress';
import { BWPaymentInstrument } from '../../../models/custom/business_wallet/BWPaymentInstrument';
import { BWCybersourceInstallmentConfiguration } from "../../../models/custom/business_wallet/BWCybersourceInstallmentConfiguration";
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import FormInputSwitch from '../../../components/general/FormInputSwitch.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import Country from '../../../components/basic data/Country.vue';
import { FilterMatchMode } from 'primevue/api';
import { Rule, fillObject, ErrorToast, satCatalogo, Toast, HeaderGrid, countries, validate } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import { PAYMENT_DICTIONARY } from "../../../utilities/BW_UTILITIES";
import Session from '../../../mixins/sessionMixin';
var math = require("mathjs");
export default {
    mixins: [Session],
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            installment_config: null,
            countries: [],
            customerHelper: {
                visible: false,
            },
            customers: [],
            customersHeaders: [new HeaderGrid('Nombre', 'name'), new HeaderGrid('Email', 'buyerInformation_email'), new HeaderGrid('Telefono', 'phone')],
            shippingsHeaders: [
                new HeaderGrid('Nombre', 'shipTo_firstName'),
                new HeaderGrid('Apellido', 'shipTo_lastName'),
                new HeaderGrid('Direccion', 'shipTo_address1'),
                new HeaderGrid('Direccion 2', 'shipTo_address2'),
                new HeaderGrid('Pais', 'shipTo_country'),
                new HeaderGrid('Localidad', 'shipTo_locality'),
            ],
            paymentParameters: new PaymentParameters(),
            customer: new BWCustomer(),
            shippingAddress: new BWShippingAddress(),
            paymentInstrument: new BWPaymentInstrument(),
            filters: {},
            customerRules: [new Rule({ name: 'name' }), new Rule({ name: 'buyerInformation_email', type: 'email' })],
            shippingRules: [    
                // new Rule({ name: 'shipTo_firstName' }), 
                // new Rule({ name: 'shipTo_lastName' }), 
                // new Rule({ name: 'shipTo_address1' }), 
                // new Rule({ name: 'shipTo_country' })],
            ],
            customerValidate: {
                valid: false,
                validations: {
                    name: null,
                    buyerInformation_email: null,
                },
            },
            shippingValidate: {
                valid: false,
                validations: {
                    // shipTo_firstName: null,
                    // shipTo_lastName: null,
                    // shipTo_address1: null,
                    // shipTo_country: null,
                },
            },
            paymentInstrumentValidate: {
                valid: false,
                validations: {
                    card_type_id: null,
                    card_expirationYear: null,
                    card_expirationMonth: null,
                    card_number: null,
                    card_cvv: null,
                },
            },
            loading: false,
            loadingInputs: false,
            c_Moneda: []
        };
    },
    components: { FormDropdownComplex, FormInputNumber, FormInputSwitch, Country, CardTokenization, Loader, Helper, BasicFormToolbar, FormInputText, FormDropdown },
    watch: {
        async ['entity.id_tm_customer'](newValue) {
            if (newValue) {
                try {
                    this.loadingInputs = true;
                    this.projectsByCustomer = this.projects.filter((project) => project.id_tm_customer == newValue);
                    if (!this.projectsByCustomer.find((project) => project.id == this.entity.id_tm_project)) {
                        this.entity.id_tm_project = null;
                    }
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                } finally {
                    this.loadingInputs = false;
                }
            }
        },
        async ['paymentParameters.paymentInformation.card.number'](newValue) {
            console.log(newValue);
           if (newValue) {
            if (newValue.length > 0) {
                let firstNumber = newValue.substring(0, 1);
                if (firstNumber == "3") 
                    this.paymentParameters.paymentInformation.card.type = {
                        id: "003",
                        text: "AmericanExpress"
                    };
                else if (firstNumber == "4") 
                    this.paymentParameters.paymentInformation.card.type = {
                        id: "001",
                        text: "Visa"
                    };
                else if (firstNumber == "5") 
                    this.paymentParameters.paymentInformation.card.type = {
                        id: "002",
                        text: "Mastercard"
                    };
            }else
                this.paymentParameters.paymentInformation.card.type = null;
           }else 
            this.paymentParameters.paymentInformation.card.type = null;
        },
    },
    computed: {
      msi() {
        let config = [];
        if (this.installment_config.three_months) 
            config.push({ value: '03', text: '3 meses'});
        if (this.installment_config.six_months) 
            config.push({ value: '06', text: '6 meses'});
        if (this.installment_config.nine_months) 
            config.push({ value: '09', text: '9 meses'});
        if (this.installment_config.twelve_months) 
            config.push({ value: '12', text: '12 meses'});
        if (this.installment_config.eighteen_months) 
            config.push({ value: '18', text: '18 meses'});
        if (this.installment_config.twentyfour_months) 
            config.push({ value: '24', text: '24 meses'});
        return config;
      },
      partialAmount() {
        if (!this.paymentParameters.orderInformationAmountDetails.totalAmount) {
          return 0;
        }else if (this.paymentParameters.orderInformationAmountDetails.totalAmount == 0 || !this.paymentParameters.installmentInformation.totalCount) {
          return 0
        }else {
          var plan = parseInt(this.paymentParameters.installmentInformation.totalCount);
          return math.chain(this.paymentParameters.orderInformationAmountDetails.totalAmount).divide(plan).done();
        }
      },
    },
    async mounted() {
        try {
            this.loading = true;
            this.c_Moneda = await satCatalogo(9);
            this.countries = await countries();
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
        await this.refresh();
    },
    methods: {
        hideDialog() {
            this.newDialog = false;
        },
        deletedPaymentInstrument(paymentInstrument) {
            this.customer.paymentInstruments.filter((x) => x.id != paymentInstrument.id);
            this.customers
                .find((x) => x.id == paymentInstrument.id_bw_cybersource_customer)
                .paymentInstruments = 
            this.customers
                .find((x) => x.id == paymentInstrument.id_bw_cybersource_customer)
                .paymentInstruments.filter((x) => x.id != paymentInstrument.id);
        },
        isNewDefaultPaymentInstrument() {
            this.customer.paymentInstruments.forEach((x) => (x.is_default = false));
            this.customers.find((x) => x.id == this.customer.id).paymentInstruments.forEach((x) => (x.is_default = false));
        },
        createdPaymentInstrument(paymentInstrument) {
            this.customers.find((x) => x.id == this.customer.id).paymentInstruments.push(paymentInstrument);
            this.paymentInstrument.id = paymentInstrument.id;
        },
        async saveCustomer() {
            try {
                this.loading = true;
                //* Datos generales del cliente
                this.customer.buyerInformation_email = this.paymentParameters.orderInformationBillTo.email;
                //! Se agrega nuevo campo obligatorio
                //this.customer.name = this.paymentParameters.orderInformationBillTo.firstName + " " + this.paymentParameters.orderInformationBillTo.lastName;
                this.customer.name = this.paymentParameters.orderInformationBillTo.name;
                this.customer.phone = this.paymentParameters.orderInformationBillTo.phoneNumber;
                //* Validamos customer
                this.customerValidate = validate(this.customer, this.customerRules);
                if (!this.customerValidate.valid) throw 'Favor de validar los campos de cliente';
                //* Rellenamos los datos de facturacion
                // this.shippingAddress.id_bw_cybersource_customer = this.customer.id;
                this.shippingAddress.is_default = this.customer.id == null ? true : this.paymentParameters.orderInformationBillTo.is_default;
                this.shippingAddress.shipTo_firstName = this.paymentParameters.orderInformationBillTo.firstName;
                this.shippingAddress.shipTo_email = this.paymentParameters.orderInformationBillTo.email;
                this.shippingAddress.shipTo_phoneNumber = this.paymentParameters.orderInformationBillTo.phoneNumber;
                this.shippingAddress.shipTo_lastName = this.paymentParameters.orderInformationBillTo.lastName;
                this.shippingAddress.shipTo_address1 = this.paymentParameters.orderInformationBillTo.address1;
                this.shippingAddress.shipTo_address2 = this.paymentParameters.orderInformationBillTo.address2;
                this.shippingAddress.shipTo_locality = this.paymentParameters.orderInformationBillTo.locality;
                this.shippingAddress.shipTo_postalCode = this.paymentParameters.orderInformationBillTo.postalCode;
                this.shippingAddress.shipTo_administrativeArea = this.paymentParameters.orderInformationBillTo.district;
                this.shippingAddress.shipTo_country = this.paymentParameters.orderInformationBillTo.country
                //* Validamos shipping
                this.shippingValidate = validate(this.shippingAddress, this.shippingRules);
                console.log(this.shippingValidate);
                if (!this.shippingValidate.valid) throw 'Favor de validar los campos de direccion';
                if (!this.customer.id) {
                    //* Creamos el customer
                    let customer = await this.customer.save();
                    this.customer.id = customer.id;
                    this.customer.clientReferenceInformation_code = customer.clientReferenceInformation_code;
                    this.customers.push(customer);
                    //* Nuevo shipping addresS
                    this.shippingAddress.id_bw_cybersource_customer = customer.id;
                    let newShippingAddress = await this.shippingAddress.save();
                    this.customers.find((x) => x.id == newShippingAddress.id_bw_cybersource_customer).shippings.push(newShippingAddress);
                    this.shippingAddress.id = newShippingAddress.id;
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion de cliente guardada con exito',
                        })
                    );
                } else {
                    //* Actualizamos el cliente
                    let customer = await this.customer.update();
                    let indexCustomer = this.customers.findIndex((x) => x.id == customer.id);
                    this.customers[indexCustomer].name = customer.name;
                    this.customers[indexCustomer].phone = customer.phone;
                    this.customers[indexCustomer].buyerInformation_email = customer.buyerInformation_email;
                    this.$toast.add(
                        new Toast({
                            summary: 'Modificacion',
                            detail: 'Informacion de cliente modificada con exito',
                        })
                    );
                    this.shippingAddress.id_bw_cybersource_customer = this.customer.id;
                    if (this.shippingAddress.id) {
                        //* Actualizamos la direccion de entrega
                        let shippingAddress = await this.shippingAddress.update();
                        let index = this.customers.find((x) => x.id == shippingAddress.id_bw_cybersource_customer).shippings.findIndex((y) => y.id == shippingAddress.id);
                        if (shippingAddress.is_default) this.customers.find((x) => x.id == shippingAddress.id_bw_cybersource_customer).shippings.forEach((x) => (x.is_default = false));
                        this.shippingAddress.id = shippingAddress.id;
                        this.customers.find((x) => x.id == shippingAddress.id_bw_cybersource_customer).shippings[index] = shippingAddress;
                        this.$toast.add(
                            new Toast({
                                summary: 'Modificacion',
                                detail: 'Informacion de direccion modificada con exito',
                            })
                        );
                    } else {
                        //* Creamos una nueva
                        let newShippingAddress = await this.shippingAddress.save();
                        if (newShippingAddress.is_default) this.customers.find((x) => x.id == newShippingAddress.id_bw_cybersource_customer).shippings.forEach((x) => (x.is_default = false));
                        this.shippingAddress.id = newShippingAddress.id;
                        //* Agregamos el nuevo shipping address a las direcciones
                        this.customers.find((x) => x.id == newShippingAddress.id_bw_cybersource_customer).shippings.push(newShippingAddress);
                        this.$toast.add(
                            new Toast({
                                summary: 'Creacion',
                                detail: 'Informacion de direccion guardada con exito',
                            })
                        );
                    }
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.loading = true;
                if (!this.paymentParameters.concept || this.paymentParameters.concept == "") throw "Favor de agregar un concepto"
                if (!this.paymentParameters.orderInformationAmountDetails.totalAmount || 
                this.paymentParameters.orderInformationAmountDetails.totalAmount == 0 || 
                this.paymentParameters.orderInformationAmountDetails.totalAmount == "") {
                    throw "Favor de agregar un importe valido"
                }
                if (this.paymentParameters.id && this.paymentParameters.id > 0) throw "Esta transaccion ya fue realizada, favor de realizar una nueva";
                if (!this.customer.id) throw "No se encontro el token para 'cliente', favor de guardar o seleccionar un registro";
                if (!this.shippingAddress.id) throw "No se encontro el token para 'direccion de envio', favor de guardar o seleccionar un registro";
                if (!this.paymentInstrument.id) throw "No se encontro el token para 'instrumento de pago', favor de guardar o seleccionar un registro";
                // * Tokenization
                this.paymentParameters.paymentInformation.paymentInstrument = {
                    id: this.paymentInstrument.id,
                };
                this.paymentParameters.paymentInformation.customer = {
                    id: this.customer.id,
                };
                this.paymentParameters.paymentInformation.shippingAddress = {
                    id: this.shippingAddress.id,
                };
                
                let payload = {
                    customer_id: this.customer.id,
                    shipping_address_id: this.shippingAddress.id,
                    payment_instrument_id: this.paymentInstrument.id,
                    amount: this.paymentParameters.orderInformationAmountDetails.totalAmount.toString(),
                    currency: this.paymentParameters.orderInformationAmountDetails.currency,
                    capture: this.paymentParameters.capture.toString(),
                    concept: this.paymentParameters.concept
                };
                if (this.paymentParameters.type == 3) {
                  payload.partialAmount = this.partialAmount.toString();
                  payload.totalCount = this.paymentParameters.installmentInformation.totalCount;
                }
                let response = await this.paymentParameters.processToken(this.paymentParameters.type, payload);
                if (response.status == "DECLINED" || response.status == "INVALID_REQUEST") {
                    let message = PAYMENT_DICTIONARY["201"].find(x => x.status == response.status
                    && x.reason ==  response.errorInformation.reason);
                    this.$toast.add({
                    severity:  response.status == "INVALID_REQUEST" ? 'error' : 'warn',
                    closable: true,
                    summary: response.status,
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario " + 
                    response.errorInformation.reason,
                    life: 30000,
                });
                }else {
                    let message = PAYMENT_DICTIONARY["201"].find(x => x.status == response.status);
                    this.paymentParameters.id = response.id;
                    this.$toast.add({
                        severity: 'success',
                        closable: true,
                        summary: response.status,
                        detail: message ? message.message_es : "No se encontró el mensaje en el diccionario",
                        life: 30000,
                    });
                    await this.paymentParameters.send(this.paymentParameters.id);
                    this.$toast.add({
                        severity: 'success',
                        closable: true,
                        summary: "Email",
                        detail: "Se ha enviado un correo al cliente con la informacion de la transaccion",
                        life: 3000,
                    });
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.newDialog = true;
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },
        async sendEmail() {
            try {
                this.loading = true;
                if (this.entity.is_closed) {
                    throw 'No puede enviar un correo de un ticket cerrado';
                }
                await this.entity.enviarCorreoNuevo();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Correo',
                    detail: 'Se envio el correo del ticket con exito',
                    life: 3000,
                });
                this.$emit('deleted');
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteShipping() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.shippingAddress.delete();
                //* Eliminamos de la vista
                this.customers.find((x) => x.id == this.shippingAddress.id_bw_cybersource_customer).shippings = this.customers
                    .find((x) => x.id == this.shippingAddress.id_bw_cybersource_customer)
                    .shippings.filter((x) => x.id != this.shippingAddress.id);
                this.paymentParameters.orderInformationBillTo.firstName = null;
                this.paymentParameters.orderInformationBillTo.lastName = null;
                this.paymentParameters.orderInformationBillTo.address1 = null;
                this.paymentParameters.orderInformationBillTo.address2 = null;
                this.paymentParameters.orderInformationBillTo.postalCode = null;
                this.paymentParameters.orderInformationBillTo.district = null;
                this.paymentParameters.orderInformationBillTo.locality = null;
                this.paymentParameters.orderInformationBillTo.country = null;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
                this.$emit('deleted');
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        selectCustomer(customer) {
            this.customer = fillObject(this.customer, customer);
            let shipping = this.customer.shippings.find((x) => x.is_default);
            if (shipping) 
                this.selectShipping(shipping)
            else
                this.shippingAddress = new BWShippingAddress(this.session);
            //* TRATAMOS DE LLENAR EL TIPO DE PAGO PREDETERMINADO
            let paymentInstrument = this.customer.paymentInstruments.find(x => x.is_default);
            if(paymentInstrument)
            {
                this.$refs.card.selectPaymentInstrument(paymentInstrument);
                this.paymentInstrument.id = paymentInstrument.id;
            }
            else
            {
                this.paymentInstrument.id = null;
                this.paymentParameters.paymentInformation.card = {
                    number: null,
                    expirationMonth: null,
                    expirationYear: null,
                    type: null,
                    useAs: 'credit',
                    securityCode: null,
                }
            }
        },
        selectShipping(shipping) {
            this.shippingAddress = fillObject(this.shippingAddress, shipping);
            let customer = this.customers.find((x) => x.id == shipping.id_bw_cybersource_customer);
            this.customer = fillObject(this.customer, customer);
            this.paymentParameters.orderInformationBillTo.name = this.customer.name;
            this.paymentParameters.orderInformationBillTo.email = this.customer.buyerInformation_email;
            this.paymentParameters.orderInformationBillTo.phoneNumber = this.customer.phone;
            this.paymentParameters.orderInformationBillTo.firstName = shipping.shipTo_firstName;
            this.paymentParameters.orderInformationBillTo.lastName = shipping.shipTo_lastName;
            this.paymentParameters.orderInformationBillTo.address1 = shipping.shipTo_address1;
            this.paymentParameters.orderInformationBillTo.address2 = shipping.shipTo_address2;
            this.paymentParameters.orderInformationBillTo.postalCode = shipping.shipTo_postalCode;
            this.paymentParameters.orderInformationBillTo.district = shipping.shipTo_administrativeArea;
            this.paymentParameters.orderInformationBillTo.locality = shipping.shipTo_locality;
            //* Tiene que ser el objecto
            this.paymentParameters.orderInformationBillTo.is_default = shipping.is_default;
            this.paymentParameters.orderInformationBillTo.country = this.countries.find((country) => country.code == shipping.shipTo_country);
            // //? Instrumento de pago predeterminado, este codigo tiene un bug pero no se por que
            // let paymentInstrument = this.customer.paymentInstruments.find(x => x.is_default);
            // if(paymentInstrument)
            //   this.selectShipping(paymentInstrument);
        },
        newPayment() {
            this.paymentParameters = new PaymentParameters(this.session);
            this.customer = new BWCustomer(this.session);
            this.shippingAddress = new BWShippingAddress(this.session);
            this.paymentInstrument = new BWPaymentInstrument(this.session);
        },
        sendWhatsapp() {
            if (this.customer.id) {
                window.open( "https://api.whatsapp.com/send?text=" + encodeURIComponent(window.location.host +  "/businesswallet/aplicaciones/terminalvirtuales?id=" + this.customer.id +"&company=" + this.session.company + "&branch=" + this.session.branch));
           }
        },
        newCustomer() {
            this.customer = new BWCustomer(this.session);
            this.shippingAddress = new BWShippingAddress(this.session);
            this.paymentInstrument = new BWPaymentInstrument(this.session);
            this.paymentParameters.orderInformationBillTo.name = null;
            this.paymentParameters.orderInformationBillTo.email = null;
            this.paymentParameters.orderInformationBillTo.phoneNumber = null;
            this.paymentParameters.orderInformationBillTo.firstName = null;
            this.paymentParameters.orderInformationBillTo.lastName = null;
            this.paymentParameters.orderInformationBillTo.address1 = null;
            this.paymentParameters.orderInformationBillTo.address2 = null;
            this.paymentParameters.orderInformationBillTo.postalCode = null;
            this.paymentParameters.orderInformationBillTo.district = null;
            this.paymentParameters.orderInformationBillTo.locality = null;
            this.paymentParameters.orderInformationBillTo.country = null;
        },
        newShipping() {
            this.shippingAddress = new BWShippingAddress(this.session);
            this.paymentInstrument = new BWPaymentInstrument(this.session);
            this.paymentParameters.orderInformationBillTo.address1 = null;
            this.paymentParameters.orderInformationBillTo.address2 = null;
            this.paymentParameters.orderInformationBillTo.postalCode = null;
            this.paymentParameters.orderInformationBillTo.district = null;
            this.paymentParameters.orderInformationBillTo.locality = null;
            this.paymentParameters.orderInformationBillTo.country = null;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async refresh() {
            this.loading = true;
            try {
                this.paymentParameters = new PaymentParameters(this.session);
                this.customer = new BWCustomer(this.session);
                this.shippingAddress = new BWShippingAddress(this.session);
                this.paymentInstrument = new BWPaymentInstrument(this.session);
                this.customers = await this.customer.all();
                this.installment_config = await new BWCybersourceInstallmentConfiguration(this.session).retrieveByBranch();
                this.customers.forEach(customer => {
                    customer.paymentInstruments.forEach(paymentInstrument => {
                        paymentInstrument.type = paymentInstrument.useAs ?? 'credit'
                    });
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped lang="scss">
</style>

